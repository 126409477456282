.animation-container {
	padding-top: 120px;
}
.animation-items-container {
	margin-top: 45px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	gap: 5%;
}

.animations-description {
	margin-top: 20px;
	font-size: 20px;
	margin-bottom: 30px;
}
