.animation-item {
	width: 25%;
	margin-bottom: 50px;
	border: 1px solid var(--border-color);
	border-radius: var(--radius-large);
	padding: 8px 12px;
	transition: all 0.2s ease-in-out;
	height: 275px;
}
.animation-item:hover {
	transform: scale(1.1);
	transition: all 0.2s ease-in-out;
}
.animation-link {
	text-decoration: none;
	color: var(--text-color);
}

.animation-thumb {
	text-align: center;
}
.animation-thumb img {
	width: auto;
	max-height: 160px;
}

.description.animation-description {
	font-size: 16px;
	padding: 5px 15px;
	line-height: 1.25;
}
