@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	transition: background-color 0.5s ease-in-out;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.5s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;
	margin: 8px;
}

.project-logo-wrap {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	gap: 10px;
	min-height: 45px;
}
.project-logo {
	width: 30px;
	align-self: center;
	text-align: center;
}

.project-logo img {
	width: 100%;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.description-container {
	position: relative;
	min-height: 145px;
	width: 260.66px;
}

.project-description {
	color: var(--secondary-color);
	min-height: 145px;
	margin-top: 15px;
	overflow: hidden;
}

.full-description {
	color: var(--secondary-color);
	display: none;
	min-height: 145px;
	max-height: 200px;
	margin-top: 15px;
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-section {
	padding-top: 10px !important;
}

.project-link-text {
	background-color: var(--lightest-gray);
	color: var(--secondary-color);
	display: block;
	font-weight: 700;
	font-size: 13px;
	text-align: center;
	padding: 3px 5px;
	transition: all, 0.3s;
}
.project-link-text:hover {
	background-color: var(--secondary-color);
	color: var(--lightest-gray);
	border-radius: 10px;
	transition: all, 0.3s;
}

.project-thumb-section {
	height: 125px;
}
.project-thumb {
	height: 125px;
	display: block;
	width: auto;
	max-width: 100%;
	background-size: cover;
	background-position: center center;
	overflow: hidden;
}
.project-thumb-img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.project-linkurl {
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.projecct-thumb {
	padding-top: 10px;
	height: 160px;
	width: 225px;
}

@media (max-width: 600px) {
	.project {
		height: 300px;
	}
}
